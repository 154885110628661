import React, { useState, useRef, useEffect } from "react"
import { Cancel, CancelRounded, Stars, TrendingUp, SubjectOutlined } from "@mui/icons-material"
import { Chrono } from "react-chrono"
import { Backdrop, Button, MenuItem } from "@mui/material"
import { Timeline, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector, TimelineDot, timelineItemClasses } from "@mui/lab"
import Dropdown from "../components/ui/Dropdown"
import { SUBJECTS, LEVELS } from "../utils/constants"
import { createArray } from "../utils/common-function"
import "./css_files/Sidebar.css"

const QuestionComponent = ({ questionStatement, handleQuestionSelect, alignment }) => {
  const [showEntireQues, setShowEntireQues] = useState(false)

  const problemStatement = questionStatement?.ProblemStatement || ""

  return (
    <>
      {questionStatement && (
        <>
          <button
            className="text-sm text-gray-200 text-left my-[0.5]"
            onClick={() => handleQuestionSelect(questionStatement.questionId, questionStatement.ToolSelected, questionStatement.Level[questionStatement.ToolSelected])}>
            {!showEntireQues ? `${problemStatement.substring(0, 100)}...` : problemStatement}
          </button>
          <div className={`flex-row-container ${alignment}`}>
            <button
              onClick={() => {
                setShowEntireQues(!showEntireQues)
              }}
              className="text-xs text-gray-400 cursor-pointer">
              {showEntireQues ? "Read Less" : "Read More"}
            </button>
          </div>
        </>
      )}
    </>
  )
}

const QuestionList = ({ questionHistory = [], handleQuestionsHistory, isOpen, onClose, currentQuestionId }) => {
  const levels = createArray(LEVELS)
  const questionRefs = useRef([])
  const [filteredQuestions, setFilteredQuestions] = useState(questionHistory)
  const [selectedTool, setSelectedTool] = useState("All")
  const [selectedLevel, setSelectedLevel] = useState("All")
  console.log({ questionHistory })

  // Scroll to the current question when the button is clicked
  const scrollToCurrentQuestion = () => {
    const questionIndex = questionHistory.findIndex((q) => q.userQuestionHistoryId === currentQuestionId)
    if (questionIndex !== -1 && questionRefs.current[questionIndex]) {
      questionRefs.current[questionIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }
  useEffect(() => {
    if (questionHistory.length > 0) {
      setFilteredQuestions(questionHistory)
    }
  }, [questionHistory])

  useEffect(() => {
    let filtered = [...questionHistory]

    if (selectedTool !== "All") {
      filtered = filtered.filter((question) => question.ToolSelected && question.ToolSelected === selectedTool)
    }

    if (selectedLevel !== "All") {
      filtered = filtered.filter((question) => question.Level && question.Level[selectedTool] && question.Level[selectedTool] === selectedLevel)
    }

    setFilteredQuestions(filtered)
  }, [selectedTool, selectedLevel, questionHistory])

  const handleQuestionSelect = (questionId, toolName, level) => {
    handleQuestionsHistory(questionId, toolName, level)
  }
  const handleToolChange = (tool) => {
    setSelectedTool(tool)
  }

  const handleLevelChange = (level) => {
    setSelectedLevel(level)
  }

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      className="backdrop-blur">
      <div
        className={`sidebar ${isOpen ? "open" : ""}`}
        style={{
          backgroundColor: "#232926",
          width: "35vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: "65vw",
          zIndex: 1,
          color: "#fefefe",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
          overflowY: "auto",
          borderRadius: "8px",
          opacity: "95%",
          backdropFilter: "blur(30px)",
          WebkitBackdropFilter: "blur(30px)",
          zIndex: "1500",
        }}>
        <div
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "#171721",
            zIndex: 1500,
          }}
          className="p-3">
          <div className="flex-row-bw-container">
            <h3 className="font-medium">
              <span className="text-gray-200">Your Journey</span>
            </h3>
            <button
              className="close-btn p-0 text-gray-200"
              onClick={onClose}>
              <Cancel />
            </button>
          </div>
          <div className="flex-row-bw-container style-list status-list my-1 text-base">
            <p className="flex items-center gap-2">
              <TimelineDot sx={{ bgcolor: "#22c55e4a" }} />
              <span className="!text-base text-[#22c55e]">Correct</span>
            </p>
            <p className="flex items-center gap-2">
              <TimelineDot sx={{ bgcolor: "#EAB3084a" }} />
              <span className="!text-base text-[#EAB308]">Attempted</span>
            </p>
            <p className="flex items-center gap-2">
              <TimelineDot sx={{ bgcolor: "#ccc9c94a" }} />
              <span className="!text-base text-[#ccc9c9]">Visited</span>
            </p>
            <p className="flex items-center gap-2">
              <TimelineDot sx={{ bgcolor: "#03c2fc4a" }} />
              <span className="!text-base text-[#03c2fc]">Current</span>
            </p>
          </div>
          <div
            className="flex-row-bw-container"
            style={{ marginTop: "10px" }}>
            {/* <div id="tool-dropdown">
              <Dropdown
                title={selectedTool || "Tools"}
                tooltip={`Select a tool`}
                icon={<SubjectOutlined />}
                children={[...SUBJECTS, "All"].map((subject) => (
                  <MenuItem
                    key={subject}
                    onClick={() => handleToolChange(subject)}
                    className="!text-gray-200 !font-light !text-xs">
                    {subject}
                  </MenuItem>
                ))}
              />
            </div>
            <div id="level-dropdown">
              <Dropdown
                title={`Level ${selectedLevel}` || "Levels"}
                tooltip={`Select a level`}
                icon={<TrendingUp />}
                children={[...levels, "All"].map((level) => (
                  <MenuItem
                    key={level}
                    onClick={() => handleLevelChange(level)}
                    className="!text-gray-200 !font-light !text-xs">
                    {level}
                  </MenuItem>
                ))}
              />
            </div> */}
            {currentQuestionId && (
              <Button
                variant="outlined"
                className="!shadow-none !text-[#03c2fc] !border-[#03c2fc] !border- !ms-4"
                onClick={scrollToCurrentQuestion}>
                Where am I ?
              </Button>
            )}
          </div>
        </div>

        <div
          className="arena-question-list bg-[#0F1015]"
          style={{ padding: "20px" }}>
          <Timeline
            className="!p-0 overflow-auto"
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}>
            {filteredQuestions.length > 0 ? (
              filteredQuestions.map((questionItem, index) => {
                if (questionItem.Level) {
                  return (
                    <TimelineItem key={questionItem.questionId}>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            bgcolor: questionItem.userQuestionHistoryId === currentQuestionId ? "#03c2fc4a" : questionItem.isAnswerCorrect ? "#22c55e4a" : questionItem.isAnswerAttempted ? "#EAB3084a" : "#CCC9C94a",
                          }}
                        />
                        <TimelineConnector
                          sx={{
                            bgcolor: questionItem.userQuestionHistoryId === currentQuestionId ? "#03c2fc4a" : questionItem.isAnswerCorrect ? "#22c55e4a" : questionItem.isAnswerAttempted ? "#EAB3084a" : "#CCC9C94a",
                          }}
                        />
                      </TimelineSeparator>
                      <TimelineContent
                        ref={(el) => (questionRefs.current[index] = el)}
                        className={`${questionItem.userQuestionHistoryId === currentQuestionId ? "current" : questionItem.isAnswerCorrect ? "solved" : questionItem.isAnswerAttempted ? "attempted" : "seen"} rounded-md transition-all cursor-pointer !my-3 !mx-2 !p-0`}>
                        <div className={`!text-gray-300 !m-[1px] !p-1`}>
                          <div className={`chrono-card-content card-left min-w-full`}>
                            <p className="p-0 text-xs me-2 px-2 py-1 rounded-md bg-red-600 bg-opacity-40">{questionItem.ToolSelected}</p>

                            <p className="p-0 text-xs me-2 px-2 py-1 rounded-md bg-red-600 bg-opacity-40">
                              <TrendingUp className="!text-base" /> {questionItem.Level ? (questionItem.Level[questionItem.ToolSelected] ? questionItem.Level[questionItem.ToolSelected] : questionItem.Level.Python) : ""}
                            </p>

                            {questionItem.isAnswerCorrect && (
                              <p className="p-0 text-xs me-2 px-2 py-1 rounded-md bg-red-600 bg-opacity-40">
                                <Stars className="!text-base" /> {questionItem.rewardPoints}
                              </p>
                            )}
                          </div>
                          <QuestionComponent
                            questionStatement={questionItem}
                            handleQuestionSelect={handleQuestionSelect}
                            alignment={"card-right min-w-full"}
                          />
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  )
                }
              })
            ) : (
              <p className="text-gray-400 text-center mt-5">No questions available for the selected tool and level.</p>
            )}
          </Timeline>
        </div>
      </div>
    </Backdrop>
  )
}

export default React.memo(QuestionList)
