import React, { useState, useEffect } from "react"
import { getBasicInfo, updateBasicInfo } from "../utils/apis" // Import the API functions
import { toast } from "react-toastify"
import { toastConfig } from "../utils/constants"
import Loader from "./loader"
import { faCancel, faEdit, faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mui/material"
import { validateGitHubUrl, validateLinkedInUrl, isValidUrl } from "../utils/common-function"
import { AddCircleOutlineRounded, Cancel, Delete, TaskAlt } from "@mui/icons-material"

const genders = ["Male", "Female", "Others"]

const BasicInfo = ({ name }) => {
  const [user, setUser] = useState({
    name: name,
    gender: "",
    birthday: new Date(),
    linkedin: "",
    github: "",
    website: "",
    summary: "",
    skills: "",
    location: "",
    experiences: [],
    education: [],
  })
  const [loading, setLoading] = useState(true) // Initially true
  const [isEditable, setIsEditable] = useState(false)
  const [newExperience, setNewExperience] = useState({})
  const [newEducation, setNewEducation] = useState({})
  const [currSkill, setCurrSkill] = useState()
  const [initialData, setInitialData] = useState({ name: name, gender: "", birthday: new Date(), linkedin: "", github: "", website: "", summary: "", skills: "", location: "", experiences: [], education: [] })

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true)
      const response = await getBasicInfo() // Fetch initial data
      if (response.status === 200 && response.data) {
        const responseData = response.data
        const revisedResponseData = {
          name: name,
          gender: responseData.gender,
          birthday: formatDateToDisplay(response.data.dateOfBirth.slice(0, 10)),
          linkedin: responseData.linkedInUrl,
          github: responseData.githubUrl,
          website: responseData.website,
          summary: responseData.summary,
          skills: responseData.technicalSkills,
          location: responseData.location,
          experiences: responseData.workExperience.map((item) => ({ ...item, startDate: formatDateToDisplay(item.startDate.slice(0, 10)), endDate: formatDateToDisplay(item.endDate.slice(0, 10)) })),
          education: responseData.educationDetails.map((item) => ({ ...item, startDate: formatDateToDisplay(item.startDate.slice(0, 10)), endDate: formatDateToDisplay(item.endDate.slice(0, 10)) })),
        }
        setUser(revisedResponseData)
        setInitialData(revisedResponseData)
      } else {
        toast.error(`${response.message}, Fill in your details.`, toastConfig)
      }
      setLoading(false)
      // setUser(data);
    }

    fetchUserData()
  }, [])

  const handleEditClick = () => {
    setIsEditable(!isEditable)
  }

  const handleChange = (event, section = null, index = null) => {
    const { name, value, type, checked } = event.target

    if (section && index !== null) {
      const newUser = { ...user }
      newUser[section][index][name] = type === "checkbox" ? checked : value
      setUser(newUser)
    } else if (section) {
      if (section === "experiences") {
        setNewExperience({ ...newExperience, [name]: type === "checkbox" ? checked : value })
      } else if (section === "education") {
        setNewEducation({ ...newEducation, [name]: type === "checkbox" ? checked : value })
      }
    } else {
      setUser({ ...user, [name]: type === "checkbox" ? checked : value })
    }
  }

  const handleAddExperience = () => {
    setUser({ ...user, experiences: [...user.experiences, newExperience] })
    setNewExperience({})
  }

  const handleAddEducation = () => {
    setUser({ ...user, education: [...user.education, newEducation] })
    setNewEducation({})
  }
  const handleAddSkill = () => {
    if (currSkill.trim() !== "") {
      setUser({ ...user, skills: [...user.skills, currSkill.trim()] })

      setCurrSkill("")
    }
  }
  const removeSkill = (skillItem) => {
    const currSkills = user.skills
    const newSkills = currSkills.filter((skill) => skill !== skillItem)
    setUser({ ...user, skills: newSkills })
  }

  const removeExperience = (index) => {
    const newExperiences = user.experiences.filter((exp, expIndex) => expIndex !== index)
    setUser({ ...user, experiences: newExperiences })
  }

  const removeEducation = (index) => {
    const newEducation = user.education.filter((edu, eduIndex) => eduIndex !== index)
    setUser({ ...user, education: newEducation })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const validLinkedIn = user.linkedin.length > 0 ? validateLinkedInUrl(user.linkedin) : true
    const validWebsite = user.website.length > 0 ? isValidUrl(user.website) : true
    const validGithub = user.github.length > 0 ? validateGitHubUrl(user.github) : true

    !validLinkedIn && toast.error("Invalid Linkedin URL", toastConfig)
    !validWebsite && toast.error("Invalid Website URL", toastConfig)
    !validGithub && toast.error("Invalid Github URL", toastConfig)
    if (!(validGithub && validLinkedIn && validWebsite)) return
    const userData = user
    if (JSON.stringify(user) === JSON.stringify(initialData)) {
      toast.error("No changes made to update", toastConfig)
      return
    }
    const response = await updateBasicInfo({ userData })
    if (response.status === 200) {
      toast.success("Profile Updated Successfully", toastConfig)
    } else {
      toast.error(response.message || "Error while updating profile", toastConfig)
    }

    setIsEditable(false)
  }
  const formatDateToDisplay = (isoDateString) => {
    if (!isoDateString) return ""
    const [year, month, day] = isoDateString.split("-")
    return `${year}-${month}-${day}`
  }

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="shiny-border mb-4 ">
        <div className="flex justify-between bg-[#1f2021]  items-baseline p-3 rounded-[18px]">
          <h2 className="font-semibold text-[#e5e7eb] text-base">Basic Info</h2>

          <div className="flex-row-container gap-2">
            <Button
              style={{ fontFamily: "inherit" }}
              variant="outlined"
              className="
            !font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600"
              endIcon={
                isEditable ? (
                  <FontAwesomeIcon
                    className="!text-sm"
                    icon={faCancel}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="!text-sm"
                    icon={faEdit}
                  />
                )
              }
              onClick={handleEditClick}>
              {isEditable ? "Cancel" : "Edit"}
            </Button>

            {isEditable && (
              <Button
                variant="outlined"
                style={{ fontFamily: "inherit" }}
                className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600"
                onClick={handleSubmit}>
                Update
                <FontAwesomeIcon
                  icon={faUpload}
                  style={{ height: "14px" }}
                />
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="shiny-border my-2">
        <div className="myCard p-5 !bg-[#1f2021]">
          <h4 className="font-medium mb-3 text-base  text-[#e5e7eb]">Bio</h4>
          <div>
            <div className="flex justify-between items-center gap-4">
              <fieldset className="profile-fieldset flex-1">
                <legend className="profile-legend">Name</legend>
                <input
                  type="text"
                  className="profile-input input mb-3"
                  placeholder="Name"
                  name="name"
                  value={name || ""}
                  disabled={true}
                />
              </fieldset>
              <fieldset className="profile-fieldset flex-1">
                <legend className="profile-legend">Gender</legend>
                <select
                  className="drop-down-container profile-input input  mb-3 "
                  placeholder="Gender"
                  name="gender"
                  disabled={!isEditable}
                  value={user.gender || "None"}
                  onChange={handleChange}>
                  <option
                    defaultValue="None"
                    disabled
                    selected
                    className="option-hide bg-[#343839]">
                    {" "}
                    None{" "}
                  </option>

                  {genders.map((gender) => (
                    <option
                      value={gender}
                      className="bg-[#343839]">
                      {gender}
                    </option>
                  ))}
                </select>
              </fieldset>
              <fieldset className="profile-fieldset flex-1">
                <legend className="profile-legend">Birthday</legend>
                <input
                  type="date"
                  className="profile-input input mb-3"
                  placeholder="Birthday"
                  name="birthday"
                  value={user.birthday || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
            </div>
            <div className="flex-row-bw-container gap-4">
              <fieldset className="profile-fieldset flex-1">
                <legend className="profile-legend">LinkedIn URL</legend>
                <input
                  type="url"
                  placeholder={isEditable ? "LinkedIn URL" : ""}
                  className="profile-input input mb-3"
                  name="linkedin"
                  value={user.linkedin || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
              <fieldset className="profile-fieldset flex-1">
                <legend className="profile-legend">GitHub URL</legend>
                <input
                  type="url"
                  placeholder={isEditable ? "GitHub URL" : ""}
                  className="profile-input input mb-3"
                  name="github"
                  value={user.github || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
            </div>
            <div className="flex-row-bw-container gap-4">
              <fieldset className="profile-fieldset flex-1">
                <legend className="profile-legend">Website/Portfolio</legend>
                <input
                  type="url"
                  placeholder={isEditable ? "Website" : ""}
                  className="profile-input input mb-3"
                  name="website"
                  value={user.website || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
              <fieldset className="profile-fieldset flex-1">
                <legend className="profile-legend">Location</legend>
                <input
                  type="text"
                  placeholder={isEditable ? "Location" : ""}
                  className="profile-input input mb-3"
                  name="location"
                  value={user.location || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
            </div>
            <fieldset className="profile-fieldset">
              <legend className="profile-legend">Summary</legend>
              <textarea
                placeholder={isEditable ? "Summary" : " "}
                className="profile-input input mb-3"
                name="summary"
                value={user.summary || ""}
                disabled={!isEditable}
                onChange={handleChange}></textarea>
            </fieldset>
          </div>
        </div>
      </div>
      <div className="shiny-border my-2">
        <div className="myCard p-5 !bg-[#1f2021]">
          <h4 className="font-medium mb-3 text-base  text-[#e5e7eb]">Technical Skills</h4>
          <fieldset className="profile-fieldset">
            <div className="flex justify-start items-center gap-2 mb-2">
              {user.skills &&
                user.skills.map((skill) => (
                  <Button
                    key={skill}
                    style={{ fontFamily: "inherit" }}
                    disabled={!isEditable}
                    variant="outlined"
                    className="!font-normal !text-sm !px-2 !py-1 !capitalize  !text-gray-200 !rounded-xl !border-gray-600"
                    endIcon={isEditable ? <Cancel /> : ""}
                    onClick={() => removeSkill(skill)}>
                    {skill}
                  </Button>
                ))}
            </div>

            {isEditable && (
              <div className="flex justify-center items-baseline gap-2 mb-2">
                <input
                  type="text"
                  placeholder={isEditable ? "Technical Skills" : ""}
                  className="profile-input input mb-3"
                  name="skills"
                  value={currSkill}
                  disabled={!isEditable}
                  onChange={(e) => setCurrSkill(e.target.value)}
                />
                {isEditable && (
                  <Button
                    variant="outlined"
                    style={{ fontFamily: "inherit" }}
                    className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 !rounded-full !text-sm !px-3 !py-1.5 !capitalize  !text-gray-400 !border-gray-600"
                    endIcon={<AddCircleOutlineRounded />}
                    onClick={handleAddSkill}>
                    Add
                  </Button>
                )}
              </div>
            )}
          </fieldset>
        </div>
      </div>
      <div className="shiny-border my-2">
        <div className="myCard p-5 !bg-[#1f2021]">
          <div className="flex-row-bw-container items-baseline">
            <h4 className="font-medium mb-3 text-base  text-[#e5e7eb]">Work Experience</h4>
            {isEditable && (
              <Button
                variant="outlined"
                style={{ fontFamily: "inherit" }}
                className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 !rounded-full !text-sm !px-3 !py-1.5 !capitalize  !text-gray-400 !border-gray-600"
                endIcon={<AddCircleOutlineRounded />}
                onClick={() => setNewExperience({ company: "", designation: "", startDate: "", endDate: "", isCurrentCompany: false })}>
                Add
              </Button>
            )}
          </div>
          {user.experiences &&
            user.experiences.map((experience, index) => (
              <div
                key={index}
                className="flex-row-bw-container mt-2">
                <div className="flex-1">
                  <div className="flex-row-container gap-4">
                    <fieldset className="flex-1 ">
                      <legend className="profile-legend">Company</legend>
                      <input
                        type="text"
                        placeholder="Company"
                        className="profile-input input mb-3"
                        name="company"
                        value={experience.company || ""}
                        disabled={!isEditable}
                        onChange={(e) => handleChange(e, "experiences", index)}
                      />
                    </fieldset>
                    <fieldset className="flex-1">
                      <legend className="profile-legend">Designation/Title</legend>
                      <input
                        type="text"
                        placeholder="Designation"
                        className="profile-input input mb-3"
                        name="title"
                        value={experience.designation || ""}
                        disabled={!isEditable}
                        onChange={(e) => handleChange(e, "experiences", index)}
                      />
                    </fieldset>
                  </div>
                  <div className="flex-row-container gap-4 items-baseline">
                    <fieldset className="flex-1">
                      <legend className="profile-legend">Start Date</legend>
                      <input
                        type="date"
                        className="profile-input input mb-3"
                        name="startDate"
                        value={experience.startDate || ""}
                        disabled={!isEditable}
                        onChange={(e) => handleChange(e, "experiences", index)}
                      />
                    </fieldset>

                    <fieldset className="flex-1">
                      <legend className="profile-legend">End Date</legend>
                      <input
                        type="date"
                        className="profile-input input mb-3"
                        name="endDate"
                        value={experience.endDate || ""}
                        disabled={!isEditable}
                        onChange={(e) => handleChange(e, "experiences", index)}
                      />
                    </fieldset>
                  </div>
                  {/*<div className="profile-present-checkbox">
                <input
                  type="checkbox"
                  name="present-company"
                  checked={experience.isCurrentCompany}
                  disabled={!isEditable}
                  onChange={(e) => handleChange(e, "experiences", index)}
                />
                <label htmlFor="present-company ml-1">Current Company</label>
              </div> */}
                </div>
                {isEditable && (
                  <button
                    disabled={!isEditable}
                    onClick={() => removeExperience(index)}
                    className="rounded-full text-gray-400 border-gray-600 hover:text-yellow-600">
                    <Delete />
                  </button>
                )}
              </div>
            ))}
          {user.experiences && user.experiences.length === 0 && !isEditable && <p>Add your work Experience</p>}
          {isEditable && (
            <div>
              {Object.keys(newExperience).length > 0 && (
                <div className="flex-row-bw-container mt-2">
                  <div className="flex-1">
                    <div className="flex-row-bw-container gap-4">
                      <fieldset className="profile-fieldset flex-1">
                        <legend className="profile-legend">Company Name</legend>
                        <input
                          type="text"
                          placeholder="Company"
                          className="profile-input input mb-3"
                          name="company"
                          value={newExperience.company || ""}
                          onChange={(e) => handleChange(e, "experiences")}
                        />
                      </fieldset>
                      <fieldset className="profile-fieldset flex-1">
                        <legend className="profile-legend">Designation/Title</legend>
                        <input
                          type="text"
                          placeholder="Designation"
                          className="profile-input input mb-3"
                          name="designation"
                          value={newExperience.designation || ""}
                          onChange={(e) => handleChange(e, "experiences")}
                        />
                      </fieldset>
                    </div>
                    <div className="flex-row-container gap-4 items-baseline">
                      <fieldset className="profile-fieldset flex-1">
                        <legend className="profile-legend">Start Date</legend>
                        <input
                          type="date"
                          className="profile-input input mb-3"
                          name="startDate"
                          value={newExperience.startDate || ""}
                          onChange={(e) => handleChange(e, "experiences")}
                        />
                      </fieldset>
                      <span> - </span>
                      <fieldset className="profile-fieldset flex-1">
                        <legend className="profile-label">End Date</legend>
                        <input
                          type="date"
                          className="profile-input input mb-3"
                          name="endDate"
                          value={newExperience.endDate || ""}
                          onChange={(e) => handleChange(e, "experiences")}
                        />
                      </fieldset>
                      {/*<div className="profile-present-checkbox">
                      <input
                        type="checkbox"
                        name="present-company"
                        checked={newExperience.isCurrentCompany || false}
                        onChange={(e) => handleChange(e, "experiences")}
                      />
                      <label
                        htmlFor="present-company"
                        className="ml-1">
                        Current Company
                      </label>
                    </div> */}
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-6">
                    <button
                      onClick={handleAddExperience}
                      className="rounded-full text-gray-400 border-gray-600 hover:text-yellow-600">
                      <TaskAlt />
                    </button>
                    <button
                      onClick={() => setNewExperience({})}
                      className="rounded-full text-gray-400 border-gray-600 hover:text-yellow-600">
                      <Cancel />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="shiny-border my-2">
        <div className="myCard p-5 !bg-[#1f2021]">
          <div className="flex-row-bw-container items-baseline">
            <h4 className="font-medium mb-3 text-base  text-[#e5e7eb]">Education</h4>
            {isEditable && (
              <Button
                variant="outlined"
                style={{ fontFamily: "inherit" }}
                className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 !rounded-full !text-sm !px-3 !py-1.5 !capitalize  !text-gray-400 !border-gray-600"
                endIcon={<AddCircleOutlineRounded />}
                onClick={() => setNewEducation({ institute: "", degree: "", startDate: "", endDate: "", isCurrentInstitute: false })}>
                Add
              </Button>
            )}
          </div>
          {user.education &&
            user.education.map((edu, index) => (
              <div
                key={index}
                className="flex-row-bw-container mt-2">
                <div className="flex-1">
                  <div className="flex-row-bw-container gap-4">
                    <fieldset className="profile-fieldset flex-1">
                      <legend className="profile-legend">Institute</legend>
                      <input
                        type="text"
                        placeholder="Institute"
                        className="profile-input input mb-3"
                        name="institute"
                        value={edu.institute || ""}
                        disabled={!isEditable}
                        onChange={(e) => handleChange(e, "education", index)}
                      />
                    </fieldset>
                    <fieldset className="profile-fieldset flex-1">
                      <legend className="profile-legend">Degree</legend>
                      <input
                        type="text"
                        placeholder="Degree"
                        className="profile-input input mb-3"
                        name="degree"
                        value={edu.degree || ""}
                        disabled={!isEditable}
                        onChange={(e) => handleChange(e, "education", index)}
                      />
                    </fieldset>
                  </div>
                  <div className="flex-row-bw-container gap-4">
                    <fieldset className="profile-fieldset flex-1">
                      <label>Deparmtent/Branch</label>
                      <input
                        type="text"
                        placeholder="Department/Branch"
                        className="profile-input input mb-3"
                        name="department"
                        value={edu.department || ""}
                        disabled={!isEditable}
                        onChange={(e) => handleChange(e, "education", index)}
                      />
                    </fieldset>
                    <fieldset className="profile-fieldset flex-1">
                      <label className="profile-label">Score</label>
                      <input
                        type="text"
                        placeholder="Score"
                        className="profile-input input mb-3"
                        name="score"
                        value={edu.score || ""}
                        disabled={!isEditable}
                        onChange={(e) => handleChange(e, "education", index)}
                      />
                    </fieldset>
                  </div>
                  <div className="flex-row-bw-container gap-4">
                    <fieldset className="profile-fieldset flex-1">
                      <label className="profile-label">Start Date</label>
                      <input
                        type="date"
                        className="profile-input input mb-3"
                        name="startDate"
                        value={edu.startDate || ""}
                        disabled={!isEditable}
                        onChange={(e) => handleChange(e, "education", index)}
                      />
                    </fieldset>
                    <fieldset className="profile-fieldset flex-1">
                      <label className="profile-label">End Date</label>
                      <input
                        type="date"
                        className="profile-input input mb-3"
                        name="endDate"
                        value={edu.endDate || ""}
                        disabled={!isEditable || edu.isCurrentInstitute}
                        onChange={(e) => handleChange(e, "education", index)}
                      />
                    </fieldset>
                  </div>
                  {/*<div className="profile-present-checkbox">
                  <input
                    type="checkbox"
                    name="present-institute"
                    checked={edu.isCurrentInstitute || false}
                    disabled={!isEditable}
                    onChange={(e) => handleChange(e, "education", index)}
                  />
                  <label htmlFor="present-institute ml-1">Current Institute</label>
                </div> */}
                </div>
                {isEditable && (
                  <Button
                    disabled={!isEditable}
                    onClick={() => removeEducation(index)}
                    className="!rounded-full !text-gray-400 !border-[#e5e7eb1a] hover:!text-yellow-600 h-[32px]">
                    <Delete />
                  </Button>
                )}
              </div>
            ))}
          {user.education && user.education.length === 0 && !isEditable && <p>Add your Education</p>}
          {isEditable && (
            <div>
              {Object.keys(newEducation).length > 0 && (
                <div className="flex-row-bw-container mt-2">
                  <div className="flex-1">
                    <div className="flex-row-bw-container gap-4">
                      <fieldset className="profile-fieldset flex-1">
                        <legend className="profile-legend">Institute</legend>
                        <input
                          type="text"
                          placeholder="Institute"
                          className="profile-input input mb-3"
                          name="institute"
                          value={newEducation.institute || ""}
                          onChange={(e) => handleChange(e, "education")}
                        />
                      </fieldset>
                      <fieldset className="profile-fieldset flex-1">
                        <legend className="profile-legend">Degree</legend>
                        <input
                          type="text"
                          placeholder="Degree"
                          className="profile-input input mb-3"
                          name="degree"
                          value={newEducation.degree || ""}
                          onChange={(e) => handleChange(e, "education")}
                        />
                      </fieldset>
                    </div>
                    <div className="flex-row-bw-container gap-4">
                      <fieldset className="profile-fieldset flex-1">
                        <legend className="profile-legend">Department/Branch</legend>
                        <input
                          type="text"
                          placeholder="Department/Branch"
                          className="profile-input input mb-3"
                          name="department"
                          value={newEducation.department || ""}
                          onChange={(e) => handleChange(e, "education")}
                        />
                      </fieldset>
                      <fieldset className="profile-fieldset flex-1">
                        <legend className="profile-legend">Score</legend>
                        <input
                          type="text"
                          placeholder="Score"
                          className="profile-input input mb-3"
                          name="score"
                          value={newEducation.score || ""}
                          onChange={(e) => handleChange(e, "education")}
                        />
                      </fieldset>
                    </div>
                    <div className="flex-row-bw-container gap-4">
                      <fieldset className="profile-fieldset flex-1">
                        <legend className="profile-legend">Start Date</legend>
                        <input
                          type="date"
                          className="profile-input input mb-3"
                          name="startDate"
                          value={newEducation.startDate.slice(0, 10) || ""}
                          onChange={(e) => handleChange(e, "education")}
                        />
                      </fieldset>
                      <fieldset className="profile-fieldset flex-1">
                        <legend className="profile-legend">End Date</legend>
                        <input
                          type="date"
                          className="profile-input input mb-3"
                          name="endDate"
                          value={newEducation.endDate || ""}
                          onChange={(e) => handleChange(e, "education")}
                        />
                      </fieldset>
                    </div>
                  </div>
                  {/*<div className="profile-present-checkbox">
                    <input
                      type="checkbox"
                      name="present-institute"
                      checked={newEducation.isCurrentInstitute || false}
                      onChange={(e) => handleChange(e, "education")}
                    />
                    <label htmlFor="present-institute ml-1">Current Institute</label>
                  </div>*/}

                  <div className="flex flex-col items-center justify-center gap-6">
                    <button
                      onClick={handleAddEducation}
                      className="rounded-full text-gray-400 border-gray-600 hover:text-yellow-600">
                      <TaskAlt />
                    </button>
                    <button
                      onClick={() => setNewEducation({})}
                      className="rounded-full text-gray-400 border-gray-600 hover:text-yellow-600">
                      <Cancel />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default BasicInfo
