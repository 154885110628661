import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import CommonHeader from "../assets/common-header"
import Loader from "../assets/loader"
import { fetchCurrentArenaData } from ".//../redux/actions/arena"
import { fetchUserDetails } from "../redux/actions/user"
import { Button, Divider, Drawer, Grid, Link, makeStyles, Tooltip } from "@material-ui/core"
import { useNavigate } from "react-router-dom"

import { fetchQuestion } from "../utils/apis"
import { toast } from "react-toastify"

import Checkout from "./checkout"
import "../assets/css_files/arenas.css"
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import EmailIcon from "@mui/icons-material/Email"
import TwitterIcon from "@mui/icons-material/Twitter"
import { CampaignOutlined, DescriptionOutlined, LeaderboardOutlined, LinkOutlined, MapOutlined } from "@mui/icons-material"
import Scrollspy from "react-scrollspy"
import Leaderboard from "./ui/arena/Leaderboard.jsx"
import RoadMapsTab from "./ui/arena/RoadMapsTab.jsx"
import { heading } from "../utils/tailwind-css.js"

function Arena() {
  const navigate = useNavigate()
  let { name } = useParams()
  const dispatch = useDispatch()
  const [activeRoadMap, setActiveRoadMap] = useState()
  const [openLeaderBoard, setOpenLeaderBoard] = useState(false)

  const [selectedToolName, setSelectedToolName] = useState()
  const [selectedLevel, setSelectedLevel] = useState()
  const [loading, setLoading] = useState(true)
  const [paymentComponent, setPaymentComponent] = useState(false)

  const { currentArena, userDetails, arenaPrice } = useSelector((state) => ({
    currentArena: state.arena.currentArena,
    userDetails: state.user.userDetails,
    arenaPrice: state.arena.currentArena.price,
  }))

  const [dailyPickQuestion, setdailyPickQuestion] = useState(null)

  const toggleLeaderBoard = (newOpen) => () => {
    setOpenLeaderBoard(newOpen)
  }

  useEffect(() => {
    const fetchArena = async () => {
      setLoading(true)
      try {
        await dispatch(fetchCurrentArenaData(name))
        await dispatch(fetchUserDetails())
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    fetchArena()
  }, [name, dispatch])

  useEffect(() => {
    if (userDetails && userDetails.questionPreferences) {
      setSelectedToolName((prev) => userDetails.questionPreferences.toolName || prev)
      setSelectedLevel((prev) => userDetails.questionPreferences.level || prev)
    } else {
      if (userDetails && !userDetails.questionPreferences) {
        toast.error("Please set your preferences in questions section to get daily pick question")
        navigate("/question")
      }
    }
  }, [userDetails])

  let arenaObject

  const handleJoin = (e) => {
    e.preventDefault()
    // Add logic to join the arena
    arenaObject = {
      ...currentArena,
      price: arenaPrice,
    }
    // Print the combined object
    console.log(arenaObject)
    setPaymentComponent(true)
  }

  const enrollButton = () => {
    return (
      <button
        className="relative inline-flex active:scale-95 transistion overflow-hidden rounded-lg p-[1px] focus:outline-none"
        onClick={handleJoin}
        disabled={currentArena.userEnrolled}>
        <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#e7029a_0%,#f472b6_50%,#bd5fff_100%)]"></span>
        <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-black bg-opacity-80 text-sm font-medium text-white backdrop-blur-3xl gap-2 undefined px-4 py-1.5">{currentArena && currentArena.userEnrolled ? "Enrolled" : "Enroll"}</span>
      </button>
    )
  }

  const useStyles = makeStyles({
    paper: {
      backgroundColor: "#191919",
    },
  })

  const classes = useStyles()

  // useEffect(() => {
  //   if (!loading && currentArena?.favPickDailyQuestionIds) {
  //     const fetchAndSetQuestion = async () => {
  //       const randomIndex = Math.floor(Math.random() * currentArena.favPickDailyQuestionIds.length)
  //       const randomQuestionId = currentArena.favPickDailyQuestionIds[randomIndex]
  //       const queryParams = new URLSearchParams({
  //         questionId: randomQuestionId,
  //         toolName: selectedToolName || "",
  //         level: selectedLevel || "",
  //         func: "",
  //       })
  //       const fetchedQuestion = await fetchQuestion(queryParams)
  //       setdailyPickQuestion(fetchedQuestion)
  //     }

  //     fetchAndSetQuestion()
  //   }
  // }, [currentArena, loading])

  if (paymentComponent) {
    return (
      <Checkout
        arena={{
          ...currentArena,
          price: arenaPrice,
        }}
        user={userDetails}
      />
    )
  }

  return (
    <div className="max-h-screen">
      <CommonHeader />
      <div className="bg-[#191919] mx-auto">
        {loading ? (
          <div className="min-h-screen flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="bg-[#191919] min-h-screen px-8">
            <Drawer
              open={openLeaderBoard}
              onClose={toggleLeaderBoard(false)}
              anchor="right"
              classes={{ paper: classes.paper }}>
              <Leaderboard arenaId={currentArena?._id} />
            </Drawer>
            <div
              id="header"
              className="flex justify-between items-center py-3 px-8 sticky top-12 z-50 bg-[#191919]">
              <div className="mx-auto">
                <h2 className="font-bold text-3xl text-gray-200">{currentArena?.name} Arena</h2>
                <div className="gradient" />
              </div>

              <div
                id="links"
                className="flex gap-3 items-center">
                {enrollButton()}

                <Button
                  variant="contained"
                  size="small"
                  className="!bg-gray-600 !text-gray-200 !capitalize !text-sm"
                  style={{ fontStyle: "inherit" }}
                  onClick={toggleLeaderBoard(true)}>
                  <LeaderboardOutlined className="me-2" />
                  Leaderboard
                </Button>

                {currentArena?.socialMediaLinks && (
                  <div className="social-links text-gray-400">
                    <a
                      href={currentArena.socialMediaLinks.linkedin}
                      target="_blank"
                      className="hover:text-[#0A66C2] transition-all"
                      rel="noreferrer noopener">
                      <LinkedInIcon />
                    </a>{" "}
                    <a
                      href={currentArena.socialMediaLinks.gmail}
                      target="_blank"
                      className="hover:text-[#c71610] transition-all"
                      rel="noreferrer noopener">
                      <EmailIcon />
                    </a>{" "}
                    <a
                      href={currentArena.socialMediaLinks.twitter}
                      target="_blank"
                      className="hover:text-[#1DA1F2] transition-all"
                      rel="noreferrer noopener">
                      <TwitterIcon />
                    </a>
                  </div>
                )}
              </div>
            </div>
            <Grid
              container
              spacing={3}
              className="relative">
              <Grid
                item
                xs={12}
                sm={3}
                md={2}
                className="sticky top-[7rem] self-start">
                <Scrollspy
                  offset={-130}
                  currentClassName={`font-bold border-r-2 border-cyan-600 !text-transparent ${heading}`}
                  className="flex flex-col gap-6 text-gray-400 transition-all"
                  items={["objective", "roadmaps"]}>
                  <Link
                    color="inherit"
                    href="#objective"
                    className="p-3 hover:bg-gray-600 hover:bg-opacity-25 transition-all !no-underline">
                    Objective
                  </Link>
                  <Link
                    color="inherit"
                    href="#roadmaps"
                    className="p-3 hover:bg-gray-600 hover:bg-opacity-25 transition-all !no-underline">
                    Roadmaps
                  </Link>
                </Scrollspy>
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                md={7}>
                <section className="flex flex-col w-full">
                  {/* First Div */}
                  <div
                    className="flex-none p-4"
                    id="objective">
                    <div className="flex items-center">
                      <DescriptionOutlined className="me-2 text-cyan-400" />
                      <h2 className={`${heading}`}>Objective of {currentArena?.name} Arena</h2>
                    </div>
                    <p className={`font-normal text-gray-200 text-left cursor-auto text-sm my-3`}>{currentArena && currentArena.description}</p>
                  </div>

                  {/* Second Div */}
                  <div id="roadmaps">
                    <div className="flex items-center px-4">
                      <MapOutlined className="me-2 text-cyan-400" />
                      <h2 className={`${heading}`}>Roadmaps</h2>
                    </div>

                    <div className="flex-1 px-4 overflow-auto">
                      <RoadMapsTab
                        currentArena={currentArena}
                        enrollButton={handleJoin}
                      />
                    </div>
                  </div>
                </section>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                direction="column"
                className="sticky right-0 top-[7rem] self-start">
                <div className="relative w-full min-h-[250px] mb-3 overflow-hidden rounded-xl bg-[#252525]">
                  <div className="absolute text-white z-10 opacity-90 rounded-xl inset-0.5 bg-[#252525] overflow-hidden">
                    <div className="relative">
                      <div className="sticky top-0 z-0">
                        <h1 className="text-xl p-2 font-semibold text-center text-gray-900 bg-[#252525] rounded-xl">
                          <span className="block">
                            <span className={`${heading}`}>Announcements</span>
                          </span>
                        </h1>
                      </div>
                    </div>

                    {/* bg-clip-text bg-gradient-to-r from-rose-500 to-rose-400 */}

                    <div
                      className="h-full overflow-auto px-3"
                      style={{ paddingBottom: "8rem" }}>
                      {currentArena?.noticeBoard?.length === 0 && <h3 className="text-gray-400 text-sm">No new updates</h3>}
                      {currentArena?.noticeBoard && currentArena?.noticeBoard?.map((notice) => <p className="mb-2 text-gray-400 text-sm">{notice}</p>)}
                    </div>
                    <span className="transparent_gradient rounded-xl bg-gradient-to-b from-transparent to-[#111111]"></span>
                  </div>
                  <div className="absolute w-56 h-48 bg-gradient-to-br from-cyan-400 to-purple-500 blur-[50px] -left-1/2 -top-1/2"></div>
                </div>

                <div className="relative w-full min-h-[250px] overflow-hidden rounded-xl bg-[#252525]">
                  <div className="absolute text-white z-10 opacity-90 rounded-xl inset-0.5 bg-[#252525] overflow-hidden">
                    <div className="relative">
                      <div className="sticky top-0 z-0">
                        <h1 className="text-xl p-2 font-semibold text-center text-gray-900 bg-[#252525] rounded-xl">
                          <span className="block">
                            <span className={`${heading}`}>Reference Links</span>
                          </span>
                        </h1>
                      </div>
                    </div>

                    <div
                      className="h-full overflow-auto px-3"
                      style={{ paddingBottom: "8rem" }}>
                      {currentArena?.referenceLinks?.length === 0 && <h3 className="text-gray-400 text-sm">No Reference Links Available</h3>}
                      {currentArena?.referenceLinks &&
                        currentArena?.referenceLinks?.map((referenceLink, i) => (
                          <Tooltip
                            title={referenceLink?.description}
                            arrow>
                            <Link
                              className="!mb-2 !text-gray-400 !text-sm block truncate"
                              underline="none"
                              color="inherit"
                              href={referenceLink?.url}>
                              <LinkOutlined className="!text-gray-300" /> {referenceLink?.description}
                            </Link>
                          </Tooltip>
                        ))}
                    </div>
                    <span className="transparent_gradient rounded-xl bg-gradient-to-b from-transparent to-[#111111]"></span>
                  </div>
                  <div className="absolute w-56 h-48 bg-gradient-to-br from-cyan-400 to-purple-500 blur-[50px] -left-1/2 -top-1/2"></div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </div>
  )
}

export default Arena

{
  /*
        <div>
          <h4>Current Daily Pick Question:</h4>
          <p>
            {dailyPickQuestion ? (
              <div>
                <a
                  href={`/question/?questionId=${dailyPickQuestion?.data?.questionId}`}
                  rel="noreferrer noopener">
                  {dailyPickQuestion?.data?.ProblemStatement}
                </a>
              </div>
            ) : (
              <Loader />
            )}
          </p>
        </div>
        */
}
