import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import RoadMapTimeline from "./RoadMapTimeline"
import { useNavigate } from "react-router-dom"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export default function RoadMapsTab({ currentArena, handleJoin }) {
  const [value, setValue] = React.useState(0)
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{ flexGrow: 1, display: "flex" }}
      className="rounded-md my-2">
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 1,
          borderColor: "divider",
          position: "sticky",
          top: 20,
          height: "100%",
          backgroundColor: "#191919",
        }}
        TabIndicatorProps={{ style: { background: "#D0AA45", color: "#D0AA45" } }}>
        {currentArena?.roadmaps &&
          currentArena?.roadmaps?.map((roadmap) => (
            <Tab
              label={roadmap?.name + " Roadmap"}
              key={roadmap?.name}
              className="!p-2 !capitalize"
              sx={{
                "&.Mui-selected": {
                  color: "#fff !important",
                },
                fontFamily: "inherit",
                color: "gray !important",
              }}></Tab>
          ))}
      </Tabs>

      {currentArena?.roadmaps?.map((roadmap, i) => {
        return (
          <TabPanel
            value={value}
            index={i}
            className="overflow-auto w-full px-5">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-gray-200 text-base">{roadmap?.name} Roadmap</h2>
              <button
                className="relative inline-flex active:scale-95 transistion overflow-hidden rounded-lg p-[1px] focus:outline-none"
                onClick={currentArena.userEnrolled ? () => navigate(`/arena-question/${currentArena.name}/${roadmap?.name}`) : handleJoin}
                //disabled={currentArena.userEnrolled}
              >
                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#e7029a_0%,#f472b6_50%,#bd5fff_100%)]"></span>
                <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-black bg-opacity-80 text-sm font-medium text-white backdrop-blur-3xl gap-2 undefined px-4 py-1.5">{currentArena && currentArena.userEnrolled ? "Dive In" : "Enroll"}</span>
              </button>
            </div>
            <p className="text-sm text-gray-400">{roadmap?.description && roadmap?.description}</p>
            <h2 className="text-gray-200 text-base my-3"> Questions</h2>

            <RoadMapTimeline
              arenaName={currentArena?.name}
              roadmapName={roadmap?.name}
              roadmap={roadmap}
              //roadmapQuestions={roadmap?.questionStatements}
              isUserEnrolled={currentArena?.userEnrolled}
            />
          </TabPanel>
        )
      })}
    </Box>
  )
}
