import React, { useState, useRef } from "react"
import "../styles.css"
import { toast } from "react-toastify"
import { isEmailValid, validateName, validatePassword } from "../utils/common-function"
import GoogleLoginComponent from "../assets/google-auth.js"
import { signupApi, verifyCaptcha } from "../utils/apis.js"
import { Link, useNavigate } from "react-router-dom"
import Popup from "reactjs-popup"
import LinkedInLoginButton from "../assets/linkedin-auth.js"
import TwitterLoginButton from "../assets/twitter-auth.js"
import ReCAPTCHA from "react-google-recaptcha"
import Checkbox from "@mui/material/Checkbox"
import { Box, Button, CircularProgress, Divider, FormControlLabel, IconButton } from "@mui/material"

// for dialog box
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { toastConfig } from "../utils/constants.js"
// import Link from 'next/link';
import BackgroundDiv from "./background/BackgroundDiv.jsx"

const Signup = () => {
  const recaptcha = useRef()

  const [firstName, setfirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [accepted, setAccepted] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [referralCode, setReferralCode] = useState("")
  const [sigupSuccessModalShow, setSignupSuccessModalShow] = useState(false)
  const handleSignupSuccessModalClose = () => {
    setSignupSuccessModalShow(false)
    setfirstName("")
    setLastName("")
    setEmail("")
    setPassword("")
  }
  const [isSubmitting, setIsSubmitting] = useState(false)

  // for T&C Dialog
  const [openTandCDialog, setOpenTandCDialog] = React.useState(false)
  const handleClickOpenTandCDialog = () => {
    if (!accepted) {
      setOpenTandCDialog(true)
    }
  }

  const navigate = useNavigate()

  //input error handling
  const [passwordError, setpasswordError] = useState({ status: false, msg: "" })
  const [firstNameError, setFirstNameError] = useState({ status: false, msg: "" })
  const [lastNameError, setLastNameError] = useState({ status: false, msg: "" })

  const checkValidPassword = (pass) => {
    const response = validatePassword(pass)
    setpasswordError(response)

    setPassword(pass)
  }

  const checkValidFirstName = (name) => {
    const response = validateName(name)
    setFirstNameError(response)

    setfirstName(name)
  }

  const checkValidLastName = (name) => {
    const response = validateName(name)
    setLastNameError(response)
    setLastName(name)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)
    if (!firstName || !email || !lastName || !password) {
      toast.dismiss()
      toast.error("Please fill all the mandatory fields", toastConfig)
      setIsSubmitting(false)

      return
    }

    if (!isEmailValid(email)) {
      toast.dismiss()
      toast.error("Invalid email", toastConfig)
      setIsSubmitting(false)

      return
    }
    if (passwordError.status) {
      toast.dismiss()
      toast.error("Please check password constraints", toastConfig)
      setIsSubmitting(false)

      return
    }
    if (firstNameError.status) {
      toast.dismiss()
      toast.error("Please check first name", toastConfig)
      setIsSubmitting(false)

      return
    }
    if (lastNameError.status) {
      toast.dismiss()
      toast.error("Please check last name", toastConfig)
      setIsSubmitting(false)

      return
    }

    if (!accepted) {
      toast.dismiss()
      toast.error("Please accept the terms and conditions to proceed.", toastConfig)
      setIsSubmitting(false)

      return
    }

    const captchaValue = recaptcha.current.getValue()
    if (!captchaValue) {
      toast.dismiss()
      toast.error("Please verify captcha", toastConfig)
      setIsSubmitting(false)
      return
    } else {
      // make form submission
      const response = await verifyCaptcha({ captchaValue })
      if (response.success) {
        try {
          const response = await signupApi({ firstName, lastName, email, password, referralCode })
          if (response.status === 201) {
            toast.dismiss()
            toast.success("Signup successful", toastConfig) // Display success toast
            navigate("/signin")
          } else {
            toast.dismiss()
            toast.error(response.message, toastConfig) // Display error toast
          }
          setIsSubmitting(false)
        } catch (error) {
          console.error(error)
          setIsSubmitting(false)
        }
      } else {
        toast.dismiss()
        toast.error("Captcha verification failed", toastConfig)
        setIsSubmitting(false)
      }
    }
  }

  return (
    <>
      <style>
        {`
          @keyframes fadeIn {
            0% {
              opacity: 0;
              transform: scale(0.9);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
          .icon-enter {
            animation: fadeIn 0.3s ease-in-out forwards;
          }
        `}
      </style>
      <BackgroundDiv>
        <div className="text-white flex justify-center items-center">
          <div className="w-full lg:w-[33%]">
            <Typography
              variant="h4"
              component="h4"
              my={2}
              align="center"
              sx={{ fontWeight: "bold" }}
              fontFamily="inherit">
              Create Account
            </Typography>

            <form
              onSubmit={handleSubmit}
              className="myCard p-6">
              <Box className="mb-3">
                <input
                  className="input"
                  required
                  type="text"
                  value={firstName}
                  onChange={(e) => checkValidFirstName(e.target.value)}
                  placeholder="First Name"
                  autocomplete="off"
                />
                {firstNameError.status && (
                  <Typography
                    my={1}
                    color="#BB2C35"
                    variant="subtitle2"
                    fontFamily="inherit"
                    gutterBottom>
                    {firstNameError.msg}
                  </Typography>
                )}
              </Box>

              <Box className="mb-3">
                <input
                  className="input"
                  required
                  type="text"
                  value={lastName}
                  onChange={(e) => checkValidLastName(e.target.value)}
                  placeholder="Last Name"
                  autocomplete="off"
                />

                {lastNameError.status && (
                  <Typography
                    my={1}
                    color="#BB2C35"
                    variant="subtitle2"
                    fontFamily="inherit"
                    gutterBottom>
                    {lastNameError.msg}
                  </Typography>
                )}
              </Box>

              <input
                className="input mb-3"
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                autocomplete="off"
              />

              <div>
                <div className="relative">
                  <input
                    className="bg-[#222630] pl-4 pr-10 py-2 outline-none w-full text-white rounded-lg border-2 transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040] text-base"
                    required
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => checkValidPassword(e.target.value)}
                    placeholder="Password"
                    autocomplete="off"
                  />
                  {/* Show/hide password toggle icon */}
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-1 flex items-center text-gray-400">
                    {showPassword ? <VisibilityOff className="icon-enter" /> : <Visibility className="icon-enter" />}
                  </button>
                </div>
                {passwordError.status && (
                  <>
                    <Typography
                      my={1}
                      color="#BB2C35"
                      variant="subtitle2"
                      fontFamily="inherit"
                      gutterBottom>
                      {passwordError.msg}
                    </Typography>
                    <div className="my-2">
                      <ul style={{ listStyle: "disc", paddingLeft: "1.5rem", marginTop: 0, color: "#94a3b8" }}>
                        <li>
                          <Typography
                            variant="subtitle2"
                            fontFamily="inherit">
                            At least 8 characters long
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            variant="subtitle2"
                            fontFamily="inherit">
                            Contains both uppercase and lowercase letters (A-Z)
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            variant="subtitle2"
                            fontFamily="inherit">
                            Contains numbers (0-9)
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            variant="subtitle2"
                            fontFamily="inherit">
                            Contains special characters (like !, @, #, $, etc.)
                          </Typography>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <input
                className="input my-3"
                required
                type="text"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                placeholder="Referral Code"
                autocomplete="off"
              />

              <div className="flex justify-start items-center my-2">
                <FormControlLabel
                  onClick={handleClickOpenTandCDialog}
                  control={
                    <Checkbox
                      sx={{
                        color: "#BCBCBE",
                        fontFamily: "inherit",
                      }}
                      onChange={() => {
                        if (accepted) {
                          setAccepted(!accepted)
                        }
                        handleClickOpenTandCDialog()
                      }}
                      checked={accepted}
                    />
                  }
                  label="Accept Terms and Conditions"
                />
                <TermsAndConditionsDialog
                  openTandCDialog={openTandCDialog}
                  setOpenTandCDialog={setOpenTandCDialog}
                  acceptFunction={() => setAccepted(true)}
                  denyFunction={() => setAccepted(false)}
                />
              </div>

              <ReCAPTCHA
                ref={recaptcha}
                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_CLIENT_KEY}
                size="normal"
                style={{
                  transform: "scale(0.77)",
                  WebkitTransform: "scale(0.77)",
                  transformOrigin: "0 0",
                  WebkitTransformOrigin: "0 0",
                }}
              />

              <Box className="flex justify-center items-center flex-col">
                {isSubmitting ? (
                  <>
                    <svg
                      width={0}
                      height={0}>
                      <defs>
                        <linearGradient
                          id="my_gradient"
                          x1="0%"
                          y1="0%"
                          x2="0%"
                          y2="100%">
                          <stop
                            offset="0%"
                            stopColor="#e01cd5"
                          />
                          <stop
                            offset="100%"
                            stopColor="#1CB5E0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                    <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
                  </>
                ) : (
                  <button
                    className="cta-btn w-full text-base rounded-full px-4 py-2 flex justify-center items-center"
                    onClick={handleSubmit}
                    style={{
                      fontFamily: "inherit",
                    }}>
                    Sign Up
                  </button>
                )}
              </Box>

              <Divider
                className="!text-base"
                sx={{
                  "&::before, &::after": {
                    borderColor: "#5F6368",
                  },
                  marginY: "1rem",
                }}>
                OR
              </Divider>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)"], // Responsive grid layout
                  gap: "1rem", // Gap between grid items
                }}
                my={2}>
                <GoogleLoginComponent />
                <LinkedInLoginButton />
                <TwitterLoginButton />
              </Box>
              <Typography
                align="center"
                my={1}
                fontFamily="inherit"
                className="!text-base">
                Already have an account?
                <Link
                  to="/signin"
                  className="font-bold text-purple-500">
                  {" "}
                  Sign In
                </Link>
              </Typography>

              <Popup
                open={sigupSuccessModalShow}
                onClose={handleSignupSuccessModalClose}>
                <div className="bg-white p-3 rounded-xl h-50 w-96 overflow-auto flex flex-col">
                  <h1>Signup Successful</h1>
                  <p>Signup successful, please signin to continue</p>
                  <br />
                  <button
                    className="bg-blue-800 mx-auto text-white rounded-2xl self-end py-2  hover:bg-blue-600 "
                    onClick={() => {
                      handleSignupSuccessModalClose()
                      navigate("/signin")
                    }}>
                    Signin
                  </button>
                </div>
              </Popup>
            </form>
          </div>
        </div>
      </BackgroundDiv>
    </>
  )
}

export default Signup

const TermsAndConditionsDialog = ({ openTandCDialog, setOpenTandCDialog, acceptFunction, denyFunction }) => {
  const handleClose = () => {
    setOpenTandCDialog(false)
  }
  return (
    <Dialog
      open={openTandCDialog}
      onClose={handleClose}>
      <DialogTitle
        id="customized-dialog-title"
        className="text-blue-500">
        <b>Terms & Conditions</b>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent>
        <Typography>The following popup contains Terms and Conditions</Typography>
        <Typography>The following popup contains Terms and Conditions</Typography>
        <Typography>The following popup contains Terms and Conditions</Typography>
        <Typography>The following popup contains Terms and Conditions</Typography>
        <Typography>The following popup contains Terms and Conditions</Typography>
        <Typography>The following popup contains Terms and Conditions</Typography>
        <Typography>The following popup contains Terms and Conditions</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            denyFunction()
            handleClose()
          }}>
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            acceptFunction()
            handleClose()
          }}>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}
